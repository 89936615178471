body {
  margin: 0;
  padding: 0;
  font-family: 'Arimo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #f7f3f1;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
